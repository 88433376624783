import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">


                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+919560130670">(+91) - 95601 30670</a></p>
                            <p>E-mail: <a href="mailto:connect@abhays.in">connect@abhays.in</a></p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default ContactInfo