import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Us
                                </span>
                                <h2>Drive Digital Revolution Through Data Science</h2>
                                <p>A vision to create and develop practices for human wellbeing and social change.
A journey of experimenting and researching to keep improvising an artistic practice for nurturing and building a better future for coming generation.
Abhay work as an actor, embodiment practitioner and artistic researcher with a constant lookout for collaborative cocreation.
He has acted for street plays, theatre productions, artistic research collaborations and community projects along with screen performances. 
 
</p>
                                
                                <p>As an embodiment practitioner he has facilitated groups in various capacities, working with the children, teachers, care givers and open workshops. He believes in harnessing the wisdom of the body to understand and expand on the individual and group resources accessing the embodied knowledge.  
He is continuously working to develop the artistic practice of systemic transformation and to support individuals and groups to bring about desired change in their own space and context. 
He is working on implementing Theory U - a framework for learning, leading, innovating, and profound systems change, founded by Otto Schammer of MIT. This system further incorporates Social Presencing Theatre, developed by Arawana Hayashi. </p>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsContent;
